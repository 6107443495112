import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter } from "react-router-dom";
import { ChainId, ThirdwebProvider } from "@thirdweb-dev/react";

const root = ReactDOM.createRoot(document.getElementById('root'));
const _appId = process.env.REACT_APP_MORALIS_APPLICATION_ID;
const _serverUrl = process.env.REACT_APP_MORALIS_SERVER_URL;

root.render(
  <>
    {/* <React.StrictMode> */}
    {/* <Router> */}
    <BrowserRouter>
        <ThirdwebProvider
        desiredChainId={80001}
        authConfig={{
            // Set this to your domain to prevent signature malleability attacks.
            domain: "mortgage.criptofor.com",
            authUrl: "/",
          }}
        >
            <App />
        </ThirdwebProvider>
    </BrowserRouter>
    {/* </Router> */}
    {/* </React.StrictMode> */}
  </>
);


import Austin from "../assets/Austin.jpeg";
import Orlando from "../assets/Orlando.jpeg";
import SanDiego from "../assets/SanDiego.jpeg";
import Atlanta from "../assets/Atlanta.jpg";
import Carolina from "../assets/Carolina.jpg";
import Clinton from "../assets/Clinton.jpg";
import Missouri from "../assets/Missouri.jpg";
import Redmond from "../assets/Redmon.jpg";

export const images = [
    {
        img: SanDiego,
        id: 0,
        value: 200000,
        dimension: 120,
        capRate: 7.5,
        address: "1501 Oak Rd, San Diego, California",
        propertyLat: 32.803963,
        propertyLng: -117.130824,
        creditScore: 754,
        downPayment: 10000,
        debtIncomeRatio: 40,
        description: "Incredible house with 3 rooms, close to the downtown"

    }
]

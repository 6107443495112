import React, { useEffect } from 'react';
import {AppRouter} from "./router/AppRouter";
import { NavLink } from "react-router-dom";
import { useChain, useMoralis } from "react-moralis";

import ResponsiveAppBar  from "./components/AppBar";

import { ThirdwebProvider } from "@thirdweb-dev/react";


function App() {



  return (
    <>
      <ResponsiveAppBar />
      <AppRouter />
    </>

  );
}

export default App;
